
import { Button, Card, DataList, IconButton } from "@/ui/index";
import { defineComponent } from "vue";
import { EmployeeController, TimeEntryController, ProjectController } from "@/controller/";
import store from "@/store/index";
import { Employee } from "@/model/index.ts";
import { DateTimeUtils, Converter } from "@/utils";

export default defineComponent({
    name: "EmployeeDetail",
    components: { Button, Card, DataList, IconButton },
    data() {
        return{
            store,
            employee:{} as Employee,
            timeTables: [] as Array<any>,
            lastActivities: [] as Array<any>
        }
    },
    computed:{
        getProfileImageSrc() {
            return "";
        },
        fullAddress(): string {
            let str = (this.employee.address?.address ? this.employee.address?.address :'')
            str += (str ? ', ' : '') + (this.employee.address?.zip ? this.employee.address?.zip :'')
            str += (str ? ' ' : '') + (this.employee.address?.city ? this.employee.address?.city :'')
            return str
        },
        dateOfBirth(): string {
            return DateTimeUtils.formatDate(DateTimeUtils.convertLinuxDateStringToDate(this.employee.getDateOfBirth()))         
        }
    },
    created() {
        if (this.$route.params.id) {
            this.handleGetEmployee(this.$route.params.id);
            this.getEmployeeTimeTables(this.$route.params.id);
            this.getLastActivities(this.$route.params.id);
        }
    },
    methods:{
        handleGoBack() {
            this.$router.go(-1);
        },
        handleEdit() {
            this.$router.push("/administration/employee/form/" + this.$route.params.id);
        },
        convertWorkTimeStamp(start:any, stop:any, totaltime:any) {
            let time = 0;
            if(start >0 && stop > 0) {
                time = stop -start;
            } else {
                time = totaltime;
            }
            var date = new Date(time * 1000);
            return date.getHours() + ":" + date.getMinutes()+ " h";
        },
        handleDeactivate() {
            console.log("[TODO] handleDeactivate")
        },
        handleIconButtonClicked() {
            console.log("[TODO] handleIconButtonClicked")
        },
        async handleGetEmployee(uid:any) {
            const res = await EmployeeController.getEmployeeById(uid);
            if (!res.error) {
                this.employee = res.employee;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getEmployeeTimeTables(uid:any) {
            //[TODO] -> get correct data
            this.timeTables = await EmployeeController.getEmployeeTimeTables(uid);
        },
        async getLastActivities(uid:any) {
                //TODO set correct date or let user decide
            const month = DateTimeUtils.addDays(new Date(), -30);
            const res = await TimeEntryController.fetchEmployeeEntries(uid, month.valueOf());

            if(!res.error){
                const timeEntries = res.items;
                //[TODO] -> Quick and dirty -> compare timestamps and check length
                if(timeEntries && timeEntries.length > 0) {
                    this.lastActivities.push(timeEntries[timeEntries.length - 1]);
                    this.lastActivities.push(timeEntries[timeEntries.length - 2]);
                    this.lastActivities.push(timeEntries[timeEntries.length - 3]);
                }
                console.log('lastActivities', this.lastActivities)
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleConfirmDeleteProject() {
            this.$confirm(this.$t("confirm.delete"), this.$t("confirm.deleteEmployeeText", { name: this.employee.firstName + this.employee.lastName }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete() },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async handleDelete() {
            const res = await EmployeeController.deleteEmployee(this.employee.uid)
            if (!res.error) {
                this.$notify(this.$t("success.employeeDeleted"), { position: "top" });
                this.$router.push("/administration/employees");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        convertSecondsToHours(seconds : number){
            return Converter.convertSecondsToTime(seconds);
        },
        convertTimeStampToDate(tstamp:any) {
            return Converter.convertTimeStampToDate(tstamp);
        },          
    }
});
